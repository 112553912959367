import datatables_initialize from "../datatables_initialize";
import yadcf_wrapper from "../yadcf_wrapper";
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="last-opt-ins-index"
export default class extends Controller {
  connect() {
    super.connect();

    const tableElement = $(this.element).find("table.dataTable");

    if (!tableElement || tableElement.length == 0) {
      console.warn("DataTable not found!");
      return;
    }

    const dataTable = datatables_initialize(tableElement);

    yadcf_wrapper(dataTable, []);
  }
}

import "selectize";

$(document).ready(function () {
  $(".selectize_free").each(function (index, element) {
    var options = $.map($(element).data("options"), function (option) {
      if (typeof option === "string") {
        return { text: option, value: option };
      }
      return option;
    });

    $(element).selectize({
      maxItems: 1,
      persist: true,
      options: options,
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      },
      inputClass: "form-control selectize-input",
      dropdownParent: "body",
      allowEmptyOption: true,
      delimiter: "|",
    });
  });

  $(".selectize_options").each(function (index, element) {
    var options = $.map($(element).data("options"), function (option) {
      if (typeof option === "string") {
        return { text: option, value: option };
      }
      return option;
    });

    $(element).selectize({
      maxItems: 1,
      persist: true,
      options: options,
      inputClass: "form-control selectize-input",
      dropdownParent: "body",
      allowEmptyOption: true,
      delimiter: "|",
    });
  });

  $(document).trigger("selectize_initialized");
});

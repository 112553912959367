$("form")
  .on("change.bs.fileinput", function (event) {
    $(event.target).find("input.remove-file-upload").val(null);
  })
  .on("clear.bs.fileinput", function (event) {
    $(event.target).find("input.remove-file-upload").val("1");
  })
  .on("reset.bs.fileinput", function (event) {
    $(event.target).find("input.remove-file-upload").val(null);
  });

import datatables_initialize from "../datatables_initialize";
import { Controller } from "@hotwired/stimulus";
import format_accreditation_sublines from "../format_accreditation_sublines_helper";

// Connects to data-controller="event-accreditation-edit"
export default class extends Controller {
  connect() {
    super.connect();

    const tableElement = $(this.element).find("table.dataTable");

    if (!tableElement || tableElement.length == 0) {
      console.warn("DataTable not found!");
      return;
    }

    const dataTable = datatables_initialize(tableElement);

    $(".dataTable .dropdown").on("click", function () {
      var tr = $(this).closest("tr");
      var row = dataTable.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
        tr.find(".less").hide();
        tr.find(".more").show();
      } else {
        // Open this row
        let companions = tr.find(".data-companions");
        let children = tr.find(".data-children");
        let combined = [];

        $(companions).each(function (i) {
          const c = $(companions[i]);
          let tmp = {};
          tmp["id"] = c.data("id");
          tmp["type"] = c.data("type");
          tmp["checkin"] = c.data("checkin");
          tmp["firstname"] = c.data("firstname");
          tmp["lastname"] = c.data("lastname");
          tmp["email"] = c.data("email");
          tmp["street"] = c.data("street");
          tmp["zip"] = c.data("zip");
          tmp["city"] = c.data("city");
          tmp["companions"] = c.data("companions");
          tmp["children"] = c.data("children");
          tmp["attend"] = c.data("attend");
          combined.push(tmp);
        });

        $(children).each(function (i) {
          const c = $(children[i]);
          let tmp = {};
          tmp["id"] = c.data("id");
          tmp["type"] = c.data("type");
          tmp["checkin"] = c.data("checkin");
          tmp["firstname"] = c.data("firstname");
          tmp["lastname"] = c.data("lastname");
          tmp["email"] = c.data("email");
          tmp["street"] = c.data("street");
          tmp["zip"] = c.data("zip");
          tmp["city"] = c.data("city");
          tmp["companions"] = c.data("companions");
          tmp["children"] = c.data("children");
          tmp["attend"] = c.data("attend");
          combined.push(tmp);
        });

        row.child(format_accreditation_sublines(combined)).show();
        tr.addClass("shown");
        tr.find(".more").hide();
        tr.find(".less").show();
      }
    });

    $(".btnCheckin").on("click", function (event) {
      event.preventDefault();
      let clicked = event.currentTarget;
      let parent = $(clicked).parent();
      let showButton = $(parent).find(".btnCheckout");
      let statusText = $(parent).find(".checkinStatus");

      let id = $(clicked).data("value");
      let checkin = true;

      // AJAX
      $.ajax({
        method: "PATCH",
        url: "/events_accreditation",
        data: { id, checkin },
      })
        .done(function (data) {
          if (data.checkin) {
            $(statusText).text("Ja");
          } else {
            $(statusText).text("Nein");
          }
          $(showButton).show();
          $(clicked).hide();
        })
        .fail(function (error) {
          console.log(error);
        });
    });

    $(".btnCheckout").on("click", function (event) {
      event.preventDefault();
      let clicked = event.currentTarget;
      let parent = $(clicked).parent();
      let showButton = $(parent).find(".btnCheckin");
      let statusText = $(parent).find(".checkinStatus");

      let id = $(clicked).data("value");
      let checkin = false;

      // AJAX
      $.ajax({
        method: "PATCH",
        url: "/events_accreditation",
        data: { id, checkin },
      })
        .done(function (data) {
          if (data.checkin) {
            $(statusText).text("Ja");
          } else {
            $(statusText).text("Nein");
          }
          $(showButton).show();
          $(clicked).hide();
        })
        .fail(function (error) {
          console.log(error);
        });
    });

    $(".more").click(function (event) {
      event.preventDefault();
    });

    $(".less").click(function (event) {
      event.preventDefault();
    });

    $(".dataTable tbody").on("click", "tr", function (event) {
      if ($(event.target).is("input")) {
        return;
      }
      if ($(event.target).is("a")) {
        return;
      }
      if ($(event.target.parentElement).is("a")) {
        return;
      }
      var url = $(this).data("show-url");
      if (url !== undefined) {
        window.open(url, "_self");
      }
    });

    $(document).trigger("datatable_initialized");
  }
}

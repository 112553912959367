export default function format_accreditation_sublines(companions) {
  let sublines = [];

  companions.forEach((companion) => {
    let yes_template = `
        <span class="checkinStatus">Ja</span>
        <button class="btnCheckin btn btn-success btn-sm" style="display: none;" data-value="${companion.id}">Checkin</button>
        <button class="btnCheckout btn btn-danger btn-sm" data-value="${companion.id}">Checkout</button>
      `;

    let no_template = `
        <span class="checkinStatus">Nein</span>
        <button class="btnCheckin btn btn-success btn-sm" data-value="${companion.id}">Checkin</button>
        <button class="btnCheckout btn btn-danger btn-sm" style="display: none;" data-value="${companion.id}">Checkout</button>
      `;

    let undefined_template = `
        <span class="checkinStatus">(unbekannt)</span>
        <button class="btnCheckin btn btn-success btn-sm" data-value="${companion.id}">Checkin</button>
        <button class="btnCheckout btn btn-danger btn-sm" data-value="${companion.id}">Checkout</button>
      `;

    let companion_template = `<tr role="row">
                                  <td>
                                  </td>
                                  <td class="checkin">
                                    ${
                                      companion.checkin == null
                                        ? undefined_template
                                        : companion.checkin
                                        ? yes_template
                                        : no_template
                                    }
                                  </td>
                                  <td class="firstname">${
                                    companion.firstname
                                  }</td>
                                  <td class="lastname">${
                                    companion.lastname
                                  }</td>
                                  <td class="email">${
                                    companion.email ? companion.email : ""
                                  }</td>
                                  <td class="street">${companion.street}</td>
                                  <td class="zip">${companion.zip}</td>
                                  <td class="city">${companion.city}</td>
                                  <td class="companions">${
                                    companion.type === "companion"
                                      ? "<i class='glyphicon glyphicon-ok text-success'/>"
                                      : ""
                                  }</td>
                                  <td class="children">${
                                    companion.type === "child"
                                      ? "<i class='glyphicon glyphicon-ok text-success' />"
                                      : ""
                                  }</td>
                                  <td class="attend">${
                                    companion.attend == undefined
                                      ? "(unbekannt)"
                                      : companion.attend
                                      ? "Ja"
                                      : "Nein"
                                  }</td>
                                  <td class="actions">
                                    <a title="Anzeigen" role="button" href="/attendees/${
                                      companion.id
                                    }">
                                      <i class="action glyphicon glyphicon-file" />
                                    </a>
                                    <a title="Bearbeiten" role="button" href="/attendees/${
                                      companion.id
                                    }/edit">
                                      <i class="action glyphicon glyphicon-pencil" />
                                    </a>
                                    <a title="Löschen" role="button" data-method="delete" data-confirm="Wirklich löschen?" href="/attendees/${
                                      companion.id
                                    }">
                                      <i class="action glyphicon glyphicon-trash" />
                                    </a>
                                  </td>
                                </tr>`;
    sublines.push($(companion_template));
  });

  return sublines;
}
